.ml-auto{
    margin-left: auto;
}
.text-right{
    text-align: right;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }
  
.profile-img{
    width: 100%;
    height: 200px;
    background: #e7e4e4;
    color: #fff;
    border-radius: 6px 6px 0 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.profile-img svg{
        width: 150px;
        height: 150px;
}
.profile-img  img{
    width: 150px;
    height: 150px;
    object-fit: contain;
}
.profile-img + button{
    border-radius: 0px 0px 6px 6px;
}

.modal-lg{
    width: 900px;
    height: 500px;
    overflow: auto;
}
.modal-sm{
    width: 400px;
}
.upload-btn{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border-radius: 0;
    background-color: #fdc513;
    padding: 5px;
    color: #fff;
    cursor: pointer;
}

.datatable .cellWithImg{
    border: 1px solid #dcdcdc;
    border-radius: 50px;
    width: 32px;
    height: 32px;
    padding: 2px;
}
.text-center{
    text-align: center;
}
div.css-1aozrgl-MuiDataGrid-root .MuiDataGrid-cellContent, div.css-1jbbcbn-MuiDataGrid-columnHeaderTitle{
    overflow: initial;
}
button.toggle-btn{
    display: none;
}
button.receipt-download{
    position: absolute;
    top: -19px;
    z-index: 1;
    right: 0;
}
.receipt{
    border: 1px solid gray;
    border-top: none;
    position: relative;
    color: #000;
    background: #fff;
}
.receipt-header{
    background: url('../../public/img/receiptHeader.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    display: flex;
    height: 195px;
}
.receipt-header .logo{
    margin: 30px 20px 50px 50px;
}
.receipt-header .content{
    background: url('../../public/img/header1.png');
    height: 81%;
    z-index: 99;
    background-position: right;
    background-size: cover;
    right: 0;
    padding: 20px 12px;
    width: 100%;
    box-sizing: border-box;
}
.receipt-header .content h2{
    font-weight: 900;
    font-size: 20px;
}
.receipt-header .content p{
    margin-bottom: 3px;
    font-size: 12px;
}
.receipt .form-content{
    padding:25px 50px;
    font-size: 13px;
    font-weight: 400;
    background: url('../../public/img/sign.png') no-repeat;
    background-size: contain;
    background-position: center;
    height: 230px;
}
.receipt .quote-left{
    left: 27px;
    top: -25px;
}
.receipt .quote-right, .receipt .quote-left{
    position: absolute;
    width: 60px;
    right: 11px;
    z-index: 1;
    bottom: -22px;
}
.receipt .field{
    margin-bottom: 10px;
    display: flex;
    width: 100%;
}
.receipt .field span:first-child{
    font-weight: 500;
    white-space: nowrap;
}
.receipt .receipt-main{
    background-color: #fff;
}
.receipt .receipt-main p{
    margin: 20px 9px;
    text-align: center;
    font-size: 13px;
}
.receipt .receipt-main h1{
    text-align: center;
    font-weight: 800;
  }
 
  .receipt .receipt-main .field span:last-child{
    margin-left: 12px;
    border-bottom: 2px dotted;
    display: inline-block;
    font-weight: 700;
    flex: 1;
  }
  .receipt-footer{
    background: url('../../public/img/receiptFooter.png') no-repeat;
    height: 150px;
    background-position: bottom;
    background-size: cover;
    justify-content: space-between;
    font-size: 12px;
    padding: 15px 50px;
    display: flex;
    background-color: #fff;
  }
  .receipt-footer .signature{
    background: url('../../public/img/stamp.png') no-repeat;
    height: 116px;
    background-position: center;
    background-size: contain;
    position: relative;
    bottom: 20px;
    width: 200px;
  }
  .receipt-footer .signature >div{
    margin-top: 68px;
    text-align:center;
  }
 
@media(max-width:1024px){
    div.modal-lg, .modal-sm{
        width: 80%;
        height: 80%;
    }
    .modal-sm{
        height: 400px;
    }
}


@media(max-width:767px){
    div.modal-lg, .modal-sm{
        width: 70%;
        height: 80%;
    }
    button.toggle-btn{
        display: inline-block;
    }
    .modal-sm{
        height: 45%;
    }
    .sidebar{
        position: absolute;
        background: #fff;
        z-index: 6;
        width: 80%;
    }
    .sidebar .center {
        overflow: auto;
        max-height: calc(100vh - 110px);
    }
    
}
