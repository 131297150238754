.app.dark {
  background-color: #111;
  color: rgb(156, 156, 156);
  }
  .app.dark .chartGrid {
    stroke: rgba(228, 228, 228, 0.219);
  }
  .app.dark .table {
    background-color: #111;

  }
  .app.dark .tableCell {
    color: gray;
  }

  .app.dark .navbar {
    color: #999;
    border-color: #333;
  }
  .app.dark .search {
    border-color: gray;
  }

  .app.dark .sidebar {
    background-color: #111;
    border-color: #333;

    
  

    
  }
  .app.dark .sidebar ul li:hover{
          background-color: #333;
  }
  .app.dark .sidebar ul li .icon{
      color: #999;
  }
  .app.dark  hr {
    border-color: #333;
  }
  
  .app.dark .top .logo{
      color: #999;
  }

  .app.dark .datatable {
  }
  .app.dark .datagrid .viewButton,
  .app.dark .deleteButton,
  .app.dark .cellWithStatus{
    color: gray;
    border: none;
  }
  .app.dark .datagrid {
    color: gray;
    border: none;
  }
  .app.dark input {
    background-color: transparent;
  }

